<template>
  <b-card
    no-body
    class="target-ad-collapse"
    :class="{ open: visible }"
  >
    <b-card-header
      ref="myCollapsible"
      :class="{ collapsed: visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="collapseOpen(!visible, $event)"
    >
      <slot name="header">
        <span class="lead collapse-title">
          <b-media class="media">
            <div class="nameClass">
              <b-link class="d-flex justify-content-between align-items-center">
                <span class="d-block font-weight-bolder text-nowrap">
                  {{ title }}
                  <span
                    v-if="approved !== null"
                    class="approval-tag-class font-weight-bold"
                  >
                    <span small>Approved</span>
                  </span>
                  <span
                    v-else
                    class="pending-tag-class font-weight-bold"
                  >
                    <span small>Pending</span>
                  </span>
                </span>
              </b-link>
            </div>
          </b-media>
        </span>
        <div class="d-flex">
          <div>
            <div
              v-if="spinner[id]"
              style="width: 75px;"
              class="spinner text-center"
            >
              <b-spinner
                variant="primary"
                small
              />
            </div>
          </div>
          <div
            class=" time"
          >
            <b-img
              size="16px"
              :src="require('@/assets/images/simiicons/wall-clock.svg')"
            />
            {{ time | moment }}
          </div>
        </div>
        <div>
          <feather-icon
            :id="id"
            icon="EyeIcon"
            class="cursor-pointer"
            size="16"
            style="margin: 8px"
            @click="review($event)"
          />
          <b-tooltip
            :disabled.sync="canEditTargetAd"
            :target="'tooltip-icon-disable' + id"
          >
            <span>Approved TargetAd cannot be edited! </span>
          </b-tooltip>
          <span :id="'tooltip-icon-disable' + id">
            <feather-icon
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover
              icon="Edit2Icon"
              :class="allowTime? 'disableIcon':'null'"
              size="16"
              style="margin: 8px; cursor: pointer"
              @click="editFunc($event, allowTime)"
            />
          </span>
          <!--          <b-tooltip-->
          <!--            :disabled.sync="canEditTargetAd"-->
          <!--            :target="'delete-icon-disable' + id"-->
          <!--          >-->
          <!--            <span>Approved TargetAd cannot be deleted! </span>-->
          <!--          </b-tooltip>-->
          <!--          <span :id="'delete-icon-disable' + id">-->
          <!--            <feather-icon-->
          <!--              :id="id"-->
          <!--              v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
          <!--              v-b-tooltip.hover-->
          <!--              icon="TrashIcon"-->
          <!--              :class="allowTime? 'disableIcon':'null'"-->
          <!--              style=" padding: 6px ; cursor: pointer"-->
          <!--              size="28"-->
          <!--              @click="deleteFunc($event, allowTime)"-->
          <!--            />-->
          <!--          </span>-->
        </div>
      </slot>
    </b-card-header>
    <div v-if="visible">
      <div class="device">
        Parameters
      </div>
    </div>
    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body style="padding: 0px !important">
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BMedia,
  BLink,
  BImg,
  BSpinner,
  VBToggle,
  VBTooltip,
  BTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import 'swiper/css/swiper.css'
import '@/assets/images/simiicons/edit.svg'
import moment from 'moment'
import TimeLimitCondition from '@/common/config'

Vue.prototype.moment = moment
Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BMedia,
    BLink,
    BImg,
    BSpinner,
    BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY h:mm a')
    },
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    title: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    targetAd: {
      type: Object,
      required: true,
    },
    approved: {
      type: Object,
    },
  },
  data() {
    return {
      TimeLimitCondition,
      allowTime: false,
      spinner: {},
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      // validation
      required,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
    canEditTargetAd: {
      get() {
        return !this.allowTime
      },
      set() {
        //
      },
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  mounted() {
    this.disabledTimeFunc()
  },
  methods: {
    disabledTimeFunc() {
      const currentTimeSpan = moment(this.time).format('YYYY-MM-DD[T]h:mm a')
      const limit = moment((Date.now() + (TimeLimitCondition.condition * 60 * 60 * 1000))).format('YYYY-MM-DD[T]h:mm a')
      const res = currentTimeSpan < limit
      this.allowTime = res || this.approved !== null
    },
    collapseOpen(val, e) {
      e.preventDefault()
      e.stopPropagation()
      this.visible = val
    },
    collapseClose() {
      if (this.openOnHover) {
        this.visible = false
      }
    },
    deleteFunc(e, allowTime) {
      e.preventDefault()
      e.stopPropagation()
      if (!allowTime) {
        this.$emit('deleteTargetAds', { id: this.id, targetAds: this.targetAd })
      }
    },
    editFunc(e, allowTime) {
      e.preventDefault()
      e.stopPropagation()
      if (!allowTime) {
        this.$emit('editTargetAds', { id: this.id })
      }
    },
    switchFunc(e) {
      e.preventDefault()
      e.stopPropagation()
      this.switchs = !this.switchs
    },
    async review(e) {
      e.preventDefault()
      e.stopPropagation()
      const allMedia = this.targetAd.target_ads_has_media.map(media => media.media)
      if (allMedia) {
        await this.$store.dispatch('lightBox/lightBoxData', allMedia)
      }
    },
  },
}
</script>
<style lang="scss">
.target-ad-collapse {
.switches {
  border-radius: 25px;
}

.device {
  font-size: 14px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 600;
  font-family: "Montserrat";
  padding-left: 15px;
}

.time {
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 21px;
  color: #414141;
  font-weight: 500;
  font-family: "Montserrat";
  margin-top: 4px;
  min-width: 240px;
}

.nameClass {
  min-width: 200px;
}

.approval-tag-class {
  background-color: rgba(40, 199, 111, 0.12);
  color: #28c76f;
  border-radius: 25px;
  display: inline-block;
  padding: 5px 15px;
}

.pending-tag-class {
  background-color: rgba(255, 192, 0, 0.22);
  color: #FFC000;
  border-radius: 25px;
  display: inline-block;
  padding: 5px 15px;
}

.disableIcon {
  color: #80808080;
}

}
</style>
