<template>
  <b-modal
    ref="delete-target-ads-1"
    centered
    hide-footer
    hide-header
  >
    <div class="d-flex justify-content-center align-content-center align-items-center bg-white mt-3 mb-3">
      <b-row style="display: block ">
        <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center">
          <img
            style="width: 80px"
            src="@/assets/images/simiicons/Delete.svg"
          >
        </div>
        <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center m-2">
          <p class="font-weight-bolder heading text-primary">
            Warining
          </p>
        </div>
        <div class="d-inline-block m-1 d-flex justify-content-center align-content-center align-items-center">
          <h4 style="font-size: 14px">
            Do you really want to cancel this TargetAd..?
          </h4>
        </div>
        <div
          class="form-group block"
        >
          <b-button
            variant="primary"
            block
            type="submit"
            :disabled="!active"
            @click="targetAdDelete(id)"
          >
            <div
              v-if="spinner"
              class="spinner"
            >
              <b-spinner
                small
              />
              <span class="sr-only">Loading...</span>
            </div>
            Continue
          </b-button>
        </div>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BButton,
  BSpinner,

} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BButton,
    BSpinner,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['id'],
  data() {
    return {
      active: true,
      spinner: false,
    }
  },
  methods: {
    show() {
      this.$refs['delete-target-ads-1'].show()
    },
    hide() {
      this.$refs['delete-target-ads-1'].hide()
    },
    async targetAdDelete(id) {
      this.spinner = true
      this.active = false
      try {
        await this.$axios.put(`target-ads/${id}`)
        this.hide()
        await this.$emit('updateSwitchState')
        this.$swal('You Canceled Successfully')
      } catch (error) {
        this.$swal(error.response.data.error)
      }
      this.hide()
      this.spinner = false
      this.active = true
    },
  },
}
</script>
<style scoped>

</style>
